/** @jsx jsx */

import ProducerTemplate from '@bottlebooks/gatsby-theme-event/src/templates/ProducerTemplate';
import { graphql } from 'gatsby';

export default ProducerTemplate;

export const pageQuery = graphql`
  query custom_ProducerTemplate(
    $id: String!
    $eventId: ID!
    $producerId: ID!
    $exhibitorId: ID!
    $locale: Bottlebooks_ContentLocale
    $previousId: String
    $nextId: String
  ) {
    bottlebooks {
      event(eventId: $eventId, locale: $locale) {
        eventId
        registrations(filter: { companyId: { eq: $exhibitorId } }) {
          nodes {
            ...bb_Registration_ProducerPage
          }
        }
        producer(exhibitorId: $exhibitorId, producerId: $producerId) {
          producerId
          products {
            nodes {
              productId
              ...custom_ProductRegion
              productId
              description
              shopImage: bottleImage {
                fixed(width: 80, height: 80, crop: PAD, background: "#FFF0") {
                  src
                }
              }
              fullName: name(includeProducer: AUTO)
            }
          }
          ...bb_ProducerPage
        }
      }
    }

    producer(id: { eq: $id }) {
      ...ProducerTemplate_Producer
      products {
        productId
      }
    }
    previous: producer(id: { eq: $previousId }) {
      ...ChangeProducerLink
    }
    next: producer(id: { eq: $nextId }) {
      ...ChangeProducerLink
    }
    ...ProducerTemplate_Query
  }

  fragment ProducerTemplate_Producer on Producer {
    ...ProducerPage
    event {
      ...ProducerPage_Event
    }
  }

  fragment ProducerTemplate_Query on Query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
